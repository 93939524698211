import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=36465b7f&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=js&"
export * from "./VideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&id=36465b7f&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36465b7f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoNative: require('/home/aydistribution/www/www.mybeers.fr/staging/components/Video/VideoNative.vue').default,VideoYoutube: require('/home/aydistribution/www/www.mybeers.fr/staging/components/Video/VideoYoutube.vue').default,VideoPlayerUI: require('/home/aydistribution/www/www.mybeers.fr/staging/components/Video/VideoPlayerUI.vue').default,ButtonPlay: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ButtonComponents/ButtonPlay.vue').default})
